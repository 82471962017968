h1,
h2,
h3,
h4,
h5 {
  font-family: 'Ubuntu', sans-serif;
  color: #222;
}

h4 {
  margin: 0;
  margin-bottom: 10px;
}

p,
a,
tr,
span,
label,
button,
input,
input[type='number'],
select,
strong {
  font-family: 'Hind Madurai', sans-serif;
  color: #222;
}

span {
  margin: 0;
  margin-top: -5px;
}

img {
  width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='file'] {
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 99;
  cursor: pointer;
}

.react-date-picker {
  width: 100%;
  height: 55px;
}

.react-date-picker__wrapper {
  padding: 16px 0 0 13px;
  border-radius: 5px;
}

.react-date-picker__button {
  margin-top: -15px;
}
